import CatalogHeader from '@common/catalogHeader';
import MobileHeader from '@common/mobileHeader';
import { ROUTE_PROMO_TRAVEL_1, ROUTE_PROMO_TRAVEL_2, ROUTE_PROMO_TRAVEL_3 } from '@src/constants';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class PromoTravelPage extends Component {
  render() {
    const { t } = this.props;

    return (
      <main className="promo-travel-page">
        <div className="wrap">
          <MobileHeader />
          <CatalogHeader pageName='Промоушены для путешествий' withPaySubscription={false}/>
          <div className="info__card">
            <h2 className="pb-1 text-lg">Список промоушенов для путешествий:</h2>
            <ul className="list-disc pl-6">
              <li><Link to={ROUTE_PROMO_TRAVEL_1}>{t('LEFT_MENU_PROMO/TRAVEL/1')}</Link></li>
              <li><Link to={ROUTE_PROMO_TRAVEL_2}>{t('LEFT_MENU_PROMO/TRAVEL/2')}</Link></li>
              <li><Link to={ROUTE_PROMO_TRAVEL_3}>{t('LEFT_MENU_PROMO/TRAVEL/3')}</Link></li>
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

export default withTranslation()(
  PromoTravelPage
);
