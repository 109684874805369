import Settings from '@common/drawer/settings';
import api from '@services/api';
import { setDrawerContent } from '@state/drawer';
import { useAppDispatch, useAppSelector } from '@state/store';
import { Button, message, Modal } from 'antd';
import { FC } from 'react';
import React from 'react';

interface IParams {
  openModal: boolean;
  closeModal: () => void;
}

export const ModalChangeEmail: FC<IParams> = ({ openModal, closeModal }) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.user.info);

  const deleteEmail = async () => {
    const { status, data } = await api.deleteUserInfo({user_id: userInfo?.id});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
    } else {
      message.error(data?.message ?? 'Ошибка сервера');
    }

    closeModal()
  }

  const openSettings = () => {
    closeModal();

    dispatch(setDrawerContent({
      content: Settings
    }));
  }

  const duplicateMail = async () => {
    const { status, data } = await api.duplicateUserInfoMail({user_id: userInfo?.id});

    if (status === 200 && data?.status === 0) {
      message.success(data?.message);
    } else {
      message.error(data?.message ?? 'Ошибка сервера');
    }

    closeModal()
  }

  return (
    <Modal
      open={openModal}
      closable={false}
      className='userInfo-modal'
      footer={(
        <div className='modal-footer'>
          <Button onClick={() => closeModal()} style={{margin: 0}}>Отложить до следующего раза</Button>
          <Button onClick={() => deleteEmail()} style={{margin: 0}}>Удалить email</Button>
          <Button onClick={() => openSettings()} style={{margin: 0}}>Изменить email</Button>
          <Button onClick={() => duplicateMail()} style={{margin: 0}}>Отправить письмо-подтверждение заново</Button>
        </div>
      )}
    >
      <h2 className='font-medium text-lg text-center modal-text'>
        Вы так и не подтвердили изменение почтового ящика.
        <br/>
        Просим вас сделать это в ближайшее время.
      </h2>
    </Modal>
  )
}
