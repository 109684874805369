import React, { Component } from 'react';
import classNames from 'classnames';
import { Popover, Segmented, Tabs } from 'antd';
import { withTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import Wallet from '@common/shortStatistics/wallet';
import { SoulEye } from '@common/soulEye';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { DECIMAL_SCALE } from '@src/constants';
import { setDrawerContent } from '@state/drawer';

import { ReactComponent as WalletIcon } from '@ui/icons/wallet.svg';
import { ReactComponent as MoneyIcon } from '@ui/icons/money.svg';

import './styles.less';
import { connect } from 'react-redux';

const {TabPane} = Tabs;

const STAT_BY_WEEK = 'week';
const STAT_BY_MONTH = 'month';
const STAT_BY_YEAR = 'year';

class ShortStatistics extends Component {
  state = {
    selectedPeriod: STAT_BY_WEEK,
    transactions: [],
    currentWalletId: undefined,
    showPopup: false,
    eyeHiddenInfo: false,
  }

  componentDidMount() {
    const { userInfo } = this.props;

    if (userInfo?.wallets?.length) {
      this.setState({currentWalletId: userInfo?.wallets?.[0].id})
    }
  }

  eyeChange = () => this.setState((prevState) => ({ eyeHiddenInfo: !prevState.eyeHiddenInfo }))

  currencyListRender = (wallets, currentWalletId, showWalletName) => {
    return (
      <table>
        <tbody>
          {wallets?.map((x) => (
            <tr
              className={classNames(
                x.id === currentWalletId
                  ? "bg-purple-200"
                  : "hover:bg-gray-200 cursor-pointer",
                " w-full px-5 py-1 "
              )}
              key={x.id}
              onClick={() => this.setState({
                currentWalletId: x.id,
                showPopup: false,
              })}
            >
              <td className="px-3">{x.currency_code}</td>
              <td className="px-3">({x.currency_network_name_code})</td>
              <td className="px-3">{x.currency_name}</td>
              {showWalletName && (
                <td className="px-3"> - {x.name}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { t, userInfo, showIncome, className } = this.props;
    const { selectedPeriod, currentWalletId, showPopup, eyeHiddenInfo } = this.state;

    const wallets = userInfo?.wallets;
    const showWalletName = wallets?.some((x) => x.name?.length) || false;
    const currentWallet = currentWalletId && wallets?.length
      ? wallets.find((x) => x.id === currentWalletId)
      : null;

    const setShowPopup = (isShow) => {
      this.setState({ showPopup: isShow });
    }

    if (
      !userInfo
      || userInfo.id === null
      || userInfo.id === undefined
    ) {
      return null;
    }

    const periods = [STAT_BY_YEAR, STAT_BY_MONTH, STAT_BY_WEEK].map((x) => ({
      value: x,
      label: (
        <div className={classNames(
          "statistics-head-item",
          selectedPeriod === x
            ? 'active'
            : '',
        )}>
          {t('STATISTICS_BY')}
          &nbsp;
          {t(`STATISTICS_BY_${x.toUpperCase()}`)}
        </div>
      ),
    }));

    // TODO: Нужно сделать загрузку данных по начислениям
    const userData = {
      allPeriod: {
        value: 12354,
      },
      year: {
        value: 12354,
        delta: 100,
      },
      month: {
        value: 10235,
        delta: 78,
      },
      week: {
        value: 2354,
        delta: 15,
      },
    }

    // TODO: Нужно сделать загрузку данных по начислениям
    const weekData = {
      [STAT_BY_YEAR]: [
        {"x_name": "2021", "value": 0},
        {"x_name": "2022", "value": 12354},
      ],
      [STAT_BY_MONTH]: [
        {"x_name": "Июнь 2022", "value": 0},
        {"x_name": "Июль 2022", "value": 2119},
        {"x_name": "Август 2022", "value": 10235},
      ],
      [STAT_BY_WEEK]: [
        {"x_name": "24", "value": 200},
        {"x_name": "25", "value": 310},
        {"x_name": "26", "value": 290},
        {"x_name": "27", "value": 450},
        {"x_name": "28", "value": 390},
        {"x_name": "29", "value": 410},
      ],
    };

    return (
      <div className="statistics relative">
        <SoulEye eyeHiddenInfo={eyeHiddenInfo} eyeChange={this.eyeChange} title='Показать/спрятать показатели в блоке' />
        <Tabs>
          <TabPane
            tab={
              <div className="tab-item flex flex-row">
                <div className="tab-icon">
                  <WalletIcon/>
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <div className="">Кошелек</div>
                  {currentWallet?.id && (
                    <div className="currency-info" key={currentWallet?.id}>
                      <Popover
                        content={this.currencyListRender(wallets, currentWalletId, showWalletName)}
                        className={classNames("!flex flex-row !items-center ", className)}
                        placement="bottom"
                        trigger={'click'}
                        open={showPopup}
                        onOpenChange={setShowPopup}
                        getPopupContainer={(node) => node?.closest('.statistics') || node}
                      >
                        <button className="flex flex-row gap-2 items-center !leading-0 currency-button">
                          <div>
                            {currentWallet.currency_code} ({currentWallet.currency_network_name_code})
                          </div>
                          <div>
                            {wallets?.length && (
                              showPopup
                                ? <CaretUpOutlined className="!leading-0" />
                                : <CaretDownOutlined className="!leading-0" />
                            )}
                          </div>
                        </button>
                      </Popover>
                    </div>
                  )}
                </div>
              </div>
            }
            key="1"
          >
            <Wallet
              showTransactions={true}
              currentWalletId={currentWalletId}
              hidden={eyeHiddenInfo}
              eyeChange={this.eyeChange}
            />
          </TabPane>
          {showIncome && (
            <TabPane
              tab={
                <div className="tab-item">
                  <div className="tab-icon">
                    <MoneyIcon/>
                  </div>
                  Заработок
                </div>
              }
              key="2"
            >
              <div className="statistics-content">
                <div className="statistics-head">
                  <Segmented
                    options={periods}
                    value={selectedPeriod}
                    onChange={(x) => this.setState({selectedPeriod: x})}
                  />
                </div>
                <div className="statistics-flex">
                  <div className="statistics-info">
                    <div className="statistics-sum">
                      <strong>
                        <NumericFormat
                          value={userData.allPeriod.value}
                          displayType={'text'}
                          decimalScale={DECIMAL_SCALE}
                          thousandSeparator=" "
                        />
                      </strong>
                      {userInfo.currency_code}
                    </div>
                    <div className="statistics-result">
                      <span>
                        {t(`STATISTICS_INCOMING_BY`)}
                        &nbsp;
                        {t(`STATISTICS_BY_${selectedPeriod.toUpperCase()}`)}
                        :
                      </span>
                      <strong>
                        <NumericFormat
                          value={userData[selectedPeriod].value}
                          displayType={'text'}
                          decimalScale={DECIMAL_SCALE}
                          thousandSeparator=" "
                        />
                        &nbsp;
                        {userInfo.currency_code}
                        &nbsp;
                        <span style={{color: "#04AA33"}}>
                          {`(${
                            userData[selectedPeriod].delta > 0
                              ? '+'
                              : (
                                userData[selectedPeriod].delta < 0
                                  ? '-'
                                  : ''
                              )
                          }${userData[selectedPeriod].delta}%)`}
                        </span>
                      </strong>
                    </div>
                  </div>
                  <div className="statistics-diagram">
                    <Line
                      data={weekData[selectedPeriod]}
                      height={200}
                      // autoFit={true}
                      xField={'x_name'}
                      yField={'value'}
                      // seriesField={'category'}
                      smooth={true}
                      state={{
                        active: {
                          style: {
                            stroke: 'l(270) 0:#8A25FC 1:#ED4AB8',
                          },
                        },
                      }}
                      color={'l(270) 0:#8A25FC 1:#ED4AB8'}
                      lineStyle={{
                        stroke: 'l(270) 0:#8A25FC 1:#ED4AB8',
                      }}
                      yAxis={{
                        label: {
                          formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s} `),
                        },
                      }}
                      tooltip={{
                        showTitle: false,
                        formatter: (datum) => {

                          return {
                            name: `
                              ${t('STATISTICS_BY')}
                              ${t(`STATISTICS_BY_${selectedPeriod.toUpperCase()}`)}
                              №
                              ${datum.week}
                            `,
                            value: `${datum.value} ${userInfo.currency_code}`,
                          };
                        },
                      }}
                    />
                  </div>
                </div>

              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
});

const mapDispatchToProps = {
  updateDrawerContent: setDrawerContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation()(
    ShortStatistics
  )
);
