import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@state/store';
import dayjs from 'dayjs';

interface ICuratorInfo {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  telegram: string;
  role_id: number;
  role_name: string;
  status_id: number;
  level: number;
}

interface INextLevelInfo {
  id: number;
  level: number;
  pv: number;
  nv: number;
  av: number;
  percent: number;
  dividend: number | null;
  company: number | null;
}

export interface IWalletInfo {
  id: number;
  currency_id: number;
  currency_code: string;
  currency_name: string;
  currency_has_pay_in: number;
  currency_has_pay_out: number;
  currency_max_fee_pay_out: number | null;
  currency_min_pay_in: number | null;
  currency_min_pay_out: number | null;
  currency_commission_pay_in: number | null;
  currency_commission_pay_out: number | null;
  currency_network_id: number | null;
  currency_network_name: string | null;
  currency_network_name_code: string | null;
  address: string | null;
  balance: number | null;
  real_balance: number | null;
  name: string | null;
  external_chain_id: string | null;
  external_address: string | null;
  date_sync_to_blockchain: string | null;
  date_sync_from_blockchain: string | null;
  blockchain_balance: number | null;
}

export interface IStructureCount {
  is_net: boolean;
  is_partner: boolean;
  cnt: number;
}

export interface ICompanyFund {
  name: string;
  code: string;
  balance: number;
}

export interface IUserInfo {
  id: number | null;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  telegram: string;
  curator_id: number | null;
  role_id: number | null;
  status_id: number;
  currency_id: number;
  create_from_company: number;
  level: number;
  is_genesis: boolean;
  pv: number;
  nv: number;
  av: number;
  subscription_date_end: string;
  prefix: string;
  country_key: string;
  country: string;
  ga2fa: boolean;
  status_name: string;
  role_name: string;
  currency_code: string;
  currency_name: string;
  tg_id?: string | number | null;
  wallets: IWalletInfo[];
  next_level: INextLevelInfo | null;
  structure_count: number;
  structure_counts: IStructureCount[];
  company_funds: ICompanyFund[];
  shopping_product_id: number;
  alternativesList: number[];
  shoppingList: number[];
  avatar: IAvatarList | null;
  curator: ICuratorInfo | null;
  isSubscribe: boolean;
  invite_to_base: string;
}

export interface IAvatarList {
  url_40?: string;
  url_80?: string;
  url_110?: string;
}
export interface IGoogleAuth {
  visible: boolean;
  loginToken?: string;
  procedure?: string;
  callBackOk?: () => void;
  callBackError?: () => void;
  callBackLogin?: (token: string, userInfo: IUserInfo) => void;
}

interface IUser {
  user_id?: number;
  token?: string;
  info?: IUserInfo;
  ga: IGoogleAuth;
}

const initialState: IUser = {
  user_id: undefined,
  token: undefined,
  info: undefined,
  ga : {
    visible: false,
    loginToken: undefined,
    procedure: undefined,
    callBackOk: undefined,
    callBackError: undefined,
    callBackLogin: undefined,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    jwtData: (state, action) => {
      state.user_id = action.payload.uid;
    },
    setUserExtraInfo: (state, action) => {
      state.info = action.payload as IUserInfo;
      state.info.isSubscribe = dayjs(state?.info?.subscription_date_end || undefined).diff(dayjs(), 'seconds') > 0
    },
    setUserStatus: (state, action) => {
      if (state.info?.status_id !== undefined) {
        state.info.status_id = action.payload;
      }
    },
    setClearUserExtraInfo: (state) => {
      state.info = undefined;
    },
    setGaVisible: (state, action: PayloadAction<IGoogleAuth>) => {
      state.ga.visible = action.payload.visible;
      state.ga.loginToken = action.payload.loginToken;
      state.ga.procedure = action.payload.procedure;
      state.ga.callBackOk = action.payload.callBackOk;
      state.ga.callBackError = action.payload.callBackError;
      state.ga.callBackLogin = action.payload.callBackLogin;
    },
    updateWalletById: (state, action) => {
      if (state.info && action.payload.id) {
        const oldWallets = state.info.wallets.filter((x) => x.id !== action.payload.id)
        const newWallets = [
          ...oldWallets,
          {...action.payload},
        ];
        state.info.wallets = newWallets.sort((a, b) => a.currency_id - b.currency_id)
      }
    },
  }
})

export const {
  setToken,
  setClearUserExtraInfo,
  setUserExtraInfo,
  setUserStatus,
  jwtData,
  updateWalletById,
  setGaVisible,
} = userSlice.actions;
export const selectUserId = (state: RootState) => state.user.user_id;
export default userSlice.reducer;
