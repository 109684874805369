import {
  buiDigits,
  buiDigitsPow,
  proxyContract,
  proxyContractAbi,
  usdtDigits, usdtDigitsPow,
} from '@common/shortStatistics/wallet/constant';
import { IPermissions } from '@routes/ambassadorsClub/myNtf/types';
import {
  getMetaMaskAccount,
  getMetaMaskChainId,
  getMetaMaskPermissions,
  getMetaMaskProvider,
  isMetaMaskConnected,
} from '@src/helpers/metaTask';
import { IWalletInfo } from '@state/user';
import { BrowserProvider, Contract, ethers } from 'ethers';
import React from 'react';

export interface getEthereumProps {
  permissions: IPermissions;
  account: string | undefined;
  chainId: string | undefined;
  provider: BrowserProvider;
  connectToWallet: boolean;
  isSavedAccountOnPermission: boolean;
  selectedSavedAccount: boolean;
  selectedSavedChainId: boolean;
  digits: number;
  digitsPow: bigint;
  currency_code: string | undefined;
  allowOperations: boolean;
  contract: Contract | undefined;
  userAccount: string | null | undefined;
  userSigner: ethers.JsonRpcSigner;
  userAddress: string | undefined;
  userContract: Contract | undefined;
  connectionError: JSX.Element | undefined;
}
export const getEthereum = async (wallet: IWalletInfo): Promise<getEthereumProps> => {
  const permissions: IPermissions = await getMetaMaskPermissions();
  const account: string | undefined = await getMetaMaskAccount();
  const chainId: string | undefined = await getMetaMaskChainId();

  const provider: BrowserProvider = getMetaMaskProvider();

  const connectToWallet: boolean = !!(isMetaMaskConnected() && account);
  const isSavedAccountOnPermission: boolean = permissions?.account.includes(wallet?.external_address || '') || false;
  const selectedSavedAccount: boolean = wallet?.external_address === account;
  const selectedSavedChainId: boolean = wallet?.external_chain_id === chainId;
  const digits: number = wallet?.currency_id === 2
    ? buiDigits
    : wallet?.currency_id === 3
      ? usdtDigits
      : 0;
  const digitsPow: bigint = wallet?.currency_id === 2
    ? buiDigitsPow
    : wallet?.currency_id === 3
      ? usdtDigitsPow
      : BigInt(0);
  const currency_code: string | undefined = wallet?.currency_code || undefined;
  const allowOperations: boolean = (
       connectToWallet
    && selectedSavedAccount
    && selectedSavedChainId
    && isSavedAccountOnPermission
  );
  const contract: Contract | undefined = allowOperations
    ? new ethers.Contract(proxyContract, proxyContractAbi, provider)
    : undefined;
  const userAccount: string | null | undefined = wallet?.external_address;
  const userSigner: ethers.JsonRpcSigner = await provider?.getSigner(0);
  const userAddress: string | undefined = await userSigner?.getAddress();
  const userContract: Contract | undefined = new ethers.Contract(proxyContract, proxyContractAbi, userSigner);

  const connectionError: JSX.Element | undefined =
    !connectToWallet
      ? <>Ошибка соединения с MetaMask-кошельком</>
      : !isSavedAccountOnPermission
        ? <>Ошибка соединения сайта с аккаунтом вывода (разрешения) в MetaMask-кошельке</>
        : !selectedSavedAccount
          ? <>Текущий аккаунт в MetaMask-кошельке не соответствует аккаунту вывода</>
          : !selectedSavedChainId
            ? <>Текущая сеть в MetaMask-кошельке не соответствует сети вывода</>
            : !provider
              ? <>Не найдено программное обеспечение для работы с MetaMask-кошельком</>
              : !contract
                ? <>Ошибка подключения к Контракту валюты вывода</>
                : !currency_code
                  ? <>Ошибка получения валюты по вашей заявке</>
                  : undefined
  ;

  return {
    permissions,
    account,
    chainId,
    provider,
    isSavedAccountOnPermission,
    connectToWallet,
    selectedSavedAccount,
    selectedSavedChainId,
    digits,
    digitsPow,
    currency_code,
    allowOperations,
    contract,
    connectionError,
    userAccount,
    userSigner,
    userAddress,
    userContract,
  }
}
