import { Amount } from '@common/amount';
import { DECIMAL_SCALE, PRODUCT_GROUP_EDUCATION } from '@src/constants';
import { useAppDispatch, useAppSelector } from '@state/store';
import React, { FC, useCallback } from 'react';

import "./styles.less";
import { setModalContent, closeModal } from '@state/modal';

export interface Product {
  id: number; // 1
  product_group_id: number; // 1
  name: string; // 'Базовый курс'
  price: number; // 10000 // руб
  subscription_price?: number; // 2500 // руб
  subscription_begin_next_month?: number; // 1
  description?: string; // ''
  disabled: number; // 0
  date_create: string; // "2022-12-24 00:00:00"
  date_update: string; // "2022-12-24 00:00:00"
  user_role_id: number; // 130
  includes_product_ids?: string|JSON; // "\"[11,12,13]\""
  user_first_level: number; // 1 1
  max_level?: number; // 4
  add_price?: number; // 6250
  add_price_period_months?: number; // 1
  add_price_from_date?: string; // "2023-01-01"
  currency_id: number; // 1
  currency_code: string; // "USDT"
  currency_network_name_code: string; // "TRC-20"
  current_sale?: number; // 50
  current_price: number; // 2092.5000000 // USDT
  current_price_with_sale: number; // 1046.2500000 // USDT
  current_subscription_sale?: number; // 25
  current_subscription_price: number; // 67.000000 // USDT
  current_subscription_price_with_sale: number; // 50.250000 // USDT
  type: string; // "product"
  productParams: ProductParam[]; //
  params: number[]; //
}

interface ProductParam {
  id: number;
  name: string;
}

interface Props {
  product: Product;
  productParams: ProductParam[];
  handleBuy: (product: Product) => void;
}

const CatalogCard: FC<Props> = ({ product, productParams, handleBuy }) => {
  const userInfo = useAppSelector((state) => state.user.info);
  const isBought = userInfo?.shoppingList?.includes(product.id) || false;
  const isMainBought = userInfo?.shopping_product_id === product.id || userInfo?.alternativesList?.includes(product.id) || false;
  const dispatch = useAppDispatch();

  const handleConfirm = useCallback(
    (product: Product) => {
      const content = (
        <>
          Вы действительно хотите приобрести <br />
          {product.product_group_id === PRODUCT_GROUP_EDUCATION ? 'Пакет' : 'статус Резидента'} "{product.name}" ?
        </>
      );
      dispatch(setModalContent({
        title: 'Подтверждение покупки',
        content,
        params: {
          onOk: () => {
            dispatch(closeModal());
            handleBuy(product);
          },
          okType: 'default',
          okButtonProps: {
            shape: 'round',
          },
          cancelButtonProps: {
            shape: 'round',
          },
        },
      }));
    },
    [dispatch, handleBuy],
  );


  return (
    <div className="info__card">
      <div className="info-content">
        <div className="info-repeat">
          <div className={`sum__inform sum__inform__product__${product.id}`}>
            <div className="sum__inform-name">
              <span>{product.product_group_id === PRODUCT_GROUP_EDUCATION ? 'Пакет' : 'Резидент'}</span>
              <h2>{product.name}</h2>
              <div className="flex flex.row gap-4">
                {isBought && (
                  <div className="sum-info-status !w-[170px]">Входит в текущий пакет</div>
                )}
                {isMainBought && (
                  <div className="sum-info-status">Текущий пакет</div>
                )}
                <div className="sum-info-status">{product.currency_network_name_code}</div>
              </div>
            </div>
            <div className="sum__inform-c">
              <div className="sum__content-info">
                <span>Доступ:</span>
                {product.current_price !== product.current_price_with_sale && (
                  <del>
                    <Amount
                      wallet={null}
                      currency_code={product.currency_code}
                      value={product.current_price}
                      digits={DECIMAL_SCALE}
                      withSpace={false}
                    />
                  </del>
                )}
                <h2>
                  <Amount
                    wallet={null}
                    currency_code={product.currency_code}
                    value={product.current_price_with_sale}
                    digits={DECIMAL_SCALE}
                    withSpace={false}
                  />
                </h2>
                {/*{Number(product.current_subscription_price) > 0 && (
                  <p>
                    Доступ
                    {product.subscription_begin_next_month ? ' cо второго месяца' : ''}
                    :
                    <br />
                    <strong>
                      <Amount
                        wallet={null}
                        currency_code={product.currency_code}
                        value={product.current_subscription_price}
                        digits={DECIMAL_SCALE}
                        withSpace={false}
                        period="мес."
                      />
                    </strong>
                  </p>
                )}*/}
              </div>
              {!isBought && !isMainBought && (
                <button
                  // disabled={isBought || isMainBought}
                  // className={(isBought || isMainBought) ? 'disabled sum__but_2 sum__but_2__product' : 'sum__but_2 sum__but_2__product'}
                  className='sum__but_2 sum__but_2__product'
                  onClick={() => handleConfirm(product)}
                >
                  <span>Присоединиться</span>
                </button>
              )}
            </div>
          </div>
          <div className="sum__content">
            <h3>Описание продукта</h3>
            <div className="sum__block">
              <div className="sum__block-flex">
                {productParams?.length > 0
                  ? productParams?.map((x) => (
                      <div
                        className="sum__block-line"
                        key={`${product.name}-${x.name}`}
                      >
                        &mdash;&nbsp;&nbsp;<span>{x.name}</span>
                      </div>
                    ))
                  : 'Пока нет информации для данного продукта'
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogCard;
