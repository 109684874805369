import storage from '@services/storage';
import { setGaVisible, setUserStatus } from '@state/user';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  DISABLE_DOUBLE_AUTH,
  ROUTE_ROOT,
  USER_STATUS_WAITING_CHANGE_EMAIL_VERIFIED,
} from '@src/constants';

import { Spin } from 'antd';

import Root from '@routes/root/root';
import api from '@services/api';
import { getMessageFromRequest } from '@src/helpers';
import { closeModal, setModalContent } from '@state/modal';
import { useAppDispatch, useAppSelector } from '@state/store';

import "./styles.less";

interface IParams {
  verifyToken: string;
}

const VerifyUserInfoRoute: FC = () => {
  const [loading, setLoading] = useState(false);
  const { verifyToken } = useParams<IParams>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userInfo = useAppSelector((state) => state.user.info);

  useEffect(() => {
    const sendVerify = async () => {
      let isError = false;
      let isSuccess = false;
      let textMessage = <>Ваша заявка на изменение личных данных успешно подтверждена и поставлена в очередь на исполнение.</>;

      const {status, data} = await api.verifyUpdateUserInfo(verifyToken);

      if (status === 200 && data?.status === 0) {
        dispatch(setUserStatus(USER_STATUS_WAITING_CHANGE_EMAIL_VERIFIED));
        isSuccess = true;
      } else {
        isError = true;
        textMessage = <>{getMessageFromRequest(data)}</>;
      }

      dispatch(setModalContent({
        title: 'Подтверждение заявки на изменение личных данных',
        content: textMessage,
        params: {
          onlyOk: true,
          onlyOkText: 'Ок',
          isError,
          isSuccess,
          status,
          data,
          onOk: () => {
            dispatch(closeModal());
            history.push(ROUTE_ROOT);
          }
        },
      }));

      setLoading(false);
    };

    const disable2Fa = storage.get(DISABLE_DOUBLE_AUTH);

    if (userInfo?.ga2fa && !disable2Fa) {
      dispatch(setGaVisible({
        visible: true,
        procedure: "подтверждения изменения данных пользователя",
        callBackOk: () => {
          setLoading(true);
          sendVerify().catch((error) => console.error(error));
        },
        callBackError: () => {
          history.push(ROUTE_ROOT);
        }
      }));
    } else {
      setLoading(true);
      sendVerify().catch((error) => console.error(error));
    }

  }, [dispatch, history, userInfo?.ga2fa, verifyToken]);

  return (
    <>
      <Root waitingChangeEmail={false} />
      <div className="relative">
        <Spin spinning={loading} className="w-full absolute" />
      </div>
    </>
  );
}

export default VerifyUserInfoRoute;
